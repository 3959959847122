import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/Layout"
import styles from '../styles/home.module.css'
import Img from "gatsby-image"

export default function Home({ data }) {
  
  
  return (
    <Layout>
      <section className={styles.header}>
        <div>
          <h2>Manu</h2>
          <h3>Somasekhar</h3>
          <p>Full stack developer based in Trivandrum.</p>
          <a className={styles.btn} href="manu_somasekhar.pdf" target="_blank">Download my resume</a>
        </div>
        <Img fluid={data.file.childImageSharp.fluid} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query Banner {
    file(relativePath: {eq: "dev.jpeg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`